<template>
  <div class="header-box">
    <a class="logo-img" href="/">
      <img :src="$imgHost + '/syg/static/img/logo.png'" />
    </a>
    <el-menu
        background-color="rgba(0,0,0,0.0)"
        :default-active="defaultActive"
        mode="horizontal"
    >
      <el-menu-item
          v-if="screenWidth >= languageLength"
          class="menu-box"
          v-for="item in menuList"
          :key="item.name"
          :index="item.url"
          :style="{ marginRight: screenWidth >= 1596 ? '32px' : '16px' }"
      >
        <a :href="item.url" @mouseenter="mouseenter(item)" @mouseleave="mouseleave">
          <span v-if="language === 'cn'">{{ screenWidth >= 1764 ? item[language] : (item.menuNameM || item[language]) }}</span>
          <span v-else>{{ item[language] }}</span>
        </a>
      </el-menu-item>
      <el-menu-item style="margin-right: 6px;">
        <img class="icon-box" :src="require('@/assets/icon/icon_search.png')" @click="onSearch" />
      </el-menu-item>
      <el-menu-item style="margin-right: 6px;">
        <img class="icon-box" :src="require('@/assets/icon/icon_user.png')" @click="onAuth" />
      </el-menu-item>
      <el-menu-item>
        <el-dropdown style="margin-top: -6px;" @command="onLang">
          <span class="el-dropdown-link">
            <img class="icon-box" :src="require(`@/assets/icon/icon_${lang}.png`)" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cn">简体中文</el-dropdown-item>
            <el-dropdown-item command="tw">繁體中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="ja">日本語</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu-item>
      <el-menu-item v-if="screenWidth < languageLength" style="margin-left: 6px;">
        <img class="icon-box" :src="require('@/assets/icon/icon_list.png')" @click="onMenu" />
      </el-menu-item>
    </el-menu>
    <div
        v-if="showSubmenu"
        class="submenu-box"
        @mouseenter="onClearTimeout"
        @mouseleave="mouseleave"
    >
      <a
          v-for="item in submenuList"
          class="submenu-box-item"
          :href="item.url"
          :style="{ margin: submenuList.length>6 ? '0 42px' : '0 50px' }"
      >{{ item.menuName }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseHeaderBox",
  props: {
    screenWidth: {
      type: Number,
      default: 1920
    }
  },
  data() {
    return {
      lang: 'cn',
      defaultActive: "/",
      menuList: menus,
      showSubmenu: false,
      submenuList: [],
      hoverMenuId: null,
      timeOut: null
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang') || 'cn';
  },
  watch: {
    $route: {
      immediate: true, // 监听到路由的变化立即执行
      handler(to) {
        let path = to.path;
        let index = path.lastIndexOf("/");
        if (index !== 0 ) path = path.substring(0, index);
        this.defaultActive = path;
      },
    }
  },
  computed: {
    languageLength() {
      switch (this.lang) {
        case "cn":
          return 1440;
        case "tw":
          return 1440;
        case "en":
          return 1860;
        case "ja":
          return 1800;
        default:
          return 1440;
      }
    },
    language() {
      switch (this.lang) {
        case "cn":
          return 'menuName';
        case "tw":
          return 'menuTWName';
        case "en":
          return 'menuENName';
        case "ja":
          return 'menuJAName';
        default:
          return 'menuName';
      }
    }
  },
  methods: {
    mouseenter(menu) {
      this.onClearTimeout();
      this.showSubmenu = false;
      this.hoverMenuId = menu.menuId;
      if (menu.menus) {
        this.submenuList = menu.menus;
        setTimeout(() => {
          this.showSubmenu = true;
        },100)
      }
    },
    mouseleave() {
      this.timeOut = setTimeout(() => {
        this.showSubmenu = false;
      },300)
    },
    onClearTimeout() {
      clearTimeout(this.timeOut);
    },
    onSearch() {
      let routeData = this.$router.resolve({
        path: "/search"
      });
      window.open(routeData.href);
    },
    onAuth() {
      let routeData = null;
      let loginToken = localStorage.getItem('loginToken');
      if (loginToken) {
        routeData = this.$router.resolve({
          path: "/user"
        });
      } else {
        routeData = this.$router.resolve({
          path: "/auth/login"
        });
      }
      window.open(routeData.href);
    },
    onLang(command) {
      this.lang = command;
      localStorage.setItem('lang', this.lang);
    },
    onMenu() {
      this.$emit('onMenu');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "header";

// 导航栏
::v-deep .el-menu-item {
  color: #000000 !important;
}
</style>
