// 建筑
export default [
    {
        path: '/build',
        name: '',
        component: () => import('@/views/Build/Build'),
        children: [
            {
                path: '',
                name: '重点建筑',
                component: () => import('@/views/Build/Show'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'evolution',
                name: '建筑沿革',
                component: () => import('@/views/Build/Evolution'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'features',
                name: '建筑特色',
                component: () => import('@/views/Build/Features'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'compare',
                name: '宫殿比较',
                component: () => import('@/views/Build/Compare'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'nous',
                name: '古建筑保护常识',
                component: () => import('@/views/Build/Nous'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'example',
                name: '文物保护公共示范',
                component: () => import('@/views/Build/Example'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    },
    {
        path: '/build/detail',
        name: '建筑详情',
        component: () => import('@/views/Build/BuildDetail'),
        meta: {
            isStandard: true,
            isShowFooter: true
        }
    }
]
