// 个人中心
export default [
    {
        path: '/user',
        name: '',
        component: () => import('@/views/User/User'),
        children: [
            {
                path: '',
                name: '基本资料',
                component: () => import('@/views/User/Info'),
                meta: {
                    requireAuth: true,
                    isStandard: true
                }
            },
            {
                path: 'star',
                name: '我的收藏',
                component: () => import('@/views/User/Star'),
                meta: {
                    requireAuth: true,
                    isStandard: true
                }
            },
            // {
            //     path: 'record',
            //     name: '预约记录',
            //     component: () => import('@/views/User/Record'),
            //     meta: {
            //         requireAuth: true,
            //         isStandard: true
            //     }
            // }
        ]
    }
]
