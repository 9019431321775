<template>
  <div>
    <el-pagination
        @size-change="changeSize"
        @current-change="changeCurrent"
        :current-page.sync="pagination.currentPage"
        :page-sizes="pageSizes"
        :page-size.sync="pagination.pageSize"
        layout="prev, pager, next, sizes"
        :background="true"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          pageSize: 10, // 每页条数
          currentPage: 1, // 当前第几页
          total: 0  // 总条数
        }
      }
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 50, 100]
      }
    },
  },
  data() {
    return {
    }
  },
  methods: {
    // pageSize 改变时会触发
    changeSize() {
      this.pagination.currentPage = 1;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit('changePage')
    },
    // currentPage 改变时会触发
    changeCurrent() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit('changePage');
    }
  }
}
</script>

<style scoped>

</style>
