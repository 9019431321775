<template>
  <div>
    <div class="footer-box" v-show="isShow">
      <img class="footer-box__bg bg" :src="$imgHost+'/syg/static/img/bg_footer.png'" />
      <div class="footer-box-content">
        <div class="menu-box">
          <div class="menu-box__title">关注我们</div>
          <div class="menu-box__code">
            <div class="code-box">
              <img class="code-box__code" :src="$imgHost+'/syg/static/img/code_gongzhonghao.png'" />
              <div class="code-box__label">
                <p>沈阳故宫博物馆</p>
                <p>微信公众号</p>
              </div>
            </div>
            <div class="code-box">
              <img class="code-box__code" :src="$imgHost+'/syg/static/img/code_dingyuehao.png'" />
              <div class="code-box__label">
                <p>沈阳故宫博物馆</p>
                <p>官方微博</p>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-box">
          <div class="menu-box__title">友情链接</div>
          <div class="menu-box__link">
            <p @click="onPage('bjggbwy')">北京故宫博物院</p>
            <p @click="onPage('zggjbwg')">中国国家博物馆</p>
            <p @click="onPage('shbwg')">上海博物馆</p>
            <p @click="onPage('zjsbwg')">浙江省博物馆</p>
            <p @click="onPage('lnsbwg')">辽宁省博物馆</p>
            <p @click="onPage('hnsbwg')">湖南省博物馆</p>
          </div>
        </div>
        <div class="menu-box">
          <div class="menu-box__title">联系我们</div>
          <div class="menu-box__contact">
            <p>地址：中国辽宁省沈阳市沈河区沈阳路171号</p>
            <p>咨询电话：024-24843001</p>
          </div>
          <div class="menu-box__btn" @click="onPage('/about/message')">在线留言</div>
        </div>
      </div>
    </div>
    <div class="copyright" :class="isShow ? 'black' : 'normal'"
    >©  沈阳故宫博物院 版权所有 <span @click="onPage('beian')">辽ICP备12001854号-1</span></div>
  </div>
</template>

<script>
export default {
  name: "BaseFooterBox",
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    onPage(url) {
      switch (url) {
          // 备案号
        case 'beian':
          window.open('https://beian.miit.gov.cn/#/Integrated/index');
          break;
          // 北京故宫博物院
        case 'bjggbwy':
          window.open('https://www.dpm.org.cn/');
          break;
          // 中国国家博物馆
        case 'zggjbwg':
          window.open('https://www.chnmuseum.cn/');
          break;
          // 上海博物馆
        case 'shbwg':
          window.open('https://www.shanghaimuseum.net/');
          break;
          // 浙江省博物馆
        case 'zjsbwg':
          window.open('https://www.zhejiangmuseum.com/');
          break;
          // 辽宁省博物馆
        case 'lnsbwg':
          window.open('http://www.lnmuseum.com.cn/');
          break;
          // 湖南省博物馆
        case 'hnsbwg':
          window.open('http://www.hnmuseum.com/');
          break;
        default:
          let routeData = this.$router.resolve(url);
          window.open(routeData.href);
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  height: 340px;
  overflow: hidden;
  position: relative;

  &__bg {
    background: #444444;
  }

  &-content {
    width: 1440px;
    margin: 0 auto;
    padding: 58px 120px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;


    .menu-box {
      //font-family: SourceHanSerifCN-Regular;
      font-size: 16px;
      color: rgba(255,255,255,0.6);
      letter-spacing: 1px;
      font-weight: 400;

      &__title {
        width: max-content;
        height: 36px;
        font-size: 22px;
        color: #D3AF68;
        letter-spacing: 1.38px;
        text-align: center;
        line-height: 30px;
        border-bottom: 1px solid #FFFFFF;
        margin-bottom: 24px;
      }

      &__code {
        width: 284px;
        display: flex;
        justify-content: space-between;

        .code-box {
          width: 113px;
          font-size: 14px;
          letter-spacing: 0.88px;
          text-align: center;

          &__code {
            width: 100px;
            height: 100px;
          }

          &__label {
            width: 100%;
          }
        }
      }

      &__link {
        width: 310px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        p {
          width: 155px;
          cursor: pointer;
          margin-bottom: 32px;
        }
      }

      &__contact {
        width: 280px;
        margin-bottom: 24px;

        p {
          margin-bottom: 10px;
        }
      }

      &__btn {
        width: 100px;
        height: 30px;
        color: #D3AF68;
        text-align: center;
        line-height: 30px;
        border: 0.5px solid rgba(255,255,255,0.6);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.copyright {
  width: 100%;
  height: 48px;
  //font-family: SourceHanSerifCN-Regular;
  font-size: 12px;
  color: #717171;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 48px;
  font-weight: 400;
  background: rgba(190,188,187,0.50);

  &.black {
    color: #FFFFFF;
    background: #000000;
  }

  &.normal {
    color: #93999E;
    background: rgba(216, 216, 216, 0.5);
  }

  span {
    cursor: pointer;
  }
}
</style>
