// 公共页面
export default [
    {
        path: '/detail',
        name: '详情',
        component: () => import('@/views/Common/Detail'),
        meta: {
            isStandard: true,
            isShowFooter: true
        }
    }
]
