import { GET, POST } from '@/utils/request'

const user = '/user';
const enjoy = user + '/enjoy';

// 改变用户信息
const changeInfo = async (params = {}) => {
    return POST(user + '/changeInfo', params)
}

// 用户信息
const userInfo = async (params = {}) => {
    return GET(user + '/info', params)
}

// 改变用户密码
const changePassword = async (params = {}) => {
    return POST(user + '/changePassword', params)
}

// 添加用户收藏
const enjoyAdd = async (params = {}) => {
    return POST(enjoy + '/add', params)
}

// 用户收藏列表
const enjoyList = async (params = {}) => {
    return POST(enjoy + '/list', params)
}

// 取消用户收藏
const enjoyCancel = async (params = {}) => {
    return POST(enjoy + '/cancel', params)
}

export {
    changeInfo,
    userInfo,
    changePassword,
    enjoyAdd,
    enjoyList,
    enjoyCancel
}
