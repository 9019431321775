<template>
  <div id="app" v-cloak>
    <template v-if="$route.meta.isStandard">
      <el-container>
        <!-- 导航栏 -->
        <template v-if="$route.name!=='Index'">
          <el-header>
            <base-header-box :screen-width="screenWidth" @onMenu="onMenu"/>
          </el-header>
          <img class="el-header-bg" :src="$imgHost+'/syg/static/img/bg.png'" />
        </template>
        <!-- 首页导航栏 -->
        <el-header v-else class="index-header">
          <base-header-box2 :screen-width="screenWidth" @onMenu="onMenu"/>
        </el-header>
        <base-menu ref="baseMenu"></base-menu>
        <el-main>
          <router-view />
        </el-main>
        <base-footer-box :is-show="$route.meta.isShowFooter" />
      </el-container>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import BaseHeaderBox from "@/components/BaseHeaderBox/BaseHeaderBox";
import BaseHeaderBox2 from "@/components/BaseHeaderBox/BaseHeaderBox2";
import BaseFooterBox from "@/components/BaseFooterBox/BaseFooterBox";
import BaseMenu from "@/components/BaseHeaderBox/BaseMenu";
import { setCookie, getCookie } from "@/utils/utils";
import { settings } from "@/api";
import {userInfo} from "@/api/user";
import {setRem} from "@/utils/rem";

export default {
  components: {
    BaseMenu,
    BaseFooterBox,
    BaseHeaderBox2,
    BaseHeaderBox
  },
  data() {
    return {
      screenWidth: null
    };
  },
  mounted() {
    let accessTime = getCookie('access_time');
    if (!accessTime) {
      // 全局参数
      settings().then(res => {
        setCookie('access_time', new Date(), 1);
      })
      let loginToken = localStorage.getItem('loginToken');
      if (loginToken) {
        // 用户信息
        userInfo().then(res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data));
        })
      }
    }
    // 获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      // 屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // 改变窗口大小时重新设置 rem
        setRem();
      })()
    }
  },
  methods: {
    onMenu() {
      this.$refs.baseMenu.showMenu = true;
    }
  }
};
</script>

<style lang="scss">
[v-cloak]{
  display: none;
}

#app {
  min-width: 1440px;
  font-family: 'Helvetica Neue', Helvetica, Tahoma, Arial, 'PingFang SC', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
  //font-family: SourceHanSerifCN-Regular;
  font-weight: 400;
  text-align: justify;
}

.el-header {
  width: 100%;
  min-width: 1440px;
  height: 88px !important;
  background: rgba(209,209,209,0.45);
  padding: 0 !important;
  z-index: 99;
}

.el-header-bg {
  width: 100%;
  height: 88px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.el-main {
  padding: 0 !important;
}

.index-header {
  background: rgba(0,0,0,0.6);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
}
</style>
