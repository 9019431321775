import { GET, POST } from '@/utils/request'

const museum = '/museum';
const es = '/es';

// 官网首页
const index = async (params = {}) => {
    return GET(museum + '/index', params)
}

// 全局参数
const settings = async (params = {}) => {
    return GET('/settings', params)
}

// 全局搜索
const globalSearch = async (params = {}) => {
    return POST(es + '/index', params)
}

// 留言
const museumMessage = async (params = {}) => {
    return POST('/museumMessage/add', params)
}

// 下载中心列表
const museumDownload = async (params = {}) => {
    return POST('/museumNews/downCenter', params)
}

// 领导信息
const leadList = async (params = {}) => {
    return POST('/museumLead/leads', params)
}

// 领导详情
const leadInfo = async (params = {}) => {
    return GET('/museumLead/info', params)
}

export {
    index,
    settings,
    globalSearch,
    museumMessage,
    museumDownload,
    leadList,
    leadInfo
}
