// 关于我们
export default [
    {
        path: '/news',
        name: '',
        component: () => import('@/views/News/News'),
        children: [
            {
                path: '',
                name: '院务快讯',
                component: () => import('@/views/News/Letter'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'exchange',
                name: '文化交流',
                component: () => import('@/views/News/Exchange'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    }
]
