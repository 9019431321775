/**
 * 富文本中 符号 的转义函数
 * @param {string} str
 */
const htmlDecode = (str) => {
    var s = "";
    if (str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    // s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#034;/g, "\"");
    s = s.replace(/&ldquo;/g, "\“");
    s = s.replace(/&rdquo;/g, "\”");
    s = s.replace(/&#39;/g, "\'");
    s = s.replace(/&quot;/g, "\"");
    s = s.replace(/<br\/>/g, "\n");
    return s;
}

/**
 * 补齐 wrap 布局
 * @param {Number} num 每列几个
 * @param {Number} length 数组长度
 */
function completionWrap(num, length) {
    let newLength = num - (length % num === 0 ? num : length % num);
    return new Array(newLength).fill('')
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
    let date = new Date();
    date.setDate(date.getDate() + day);
    document.cookie = name + '=' + value + ';expires=' + date;
}

/**
 * 获取cookie
 **/
function getCookie(name) {
    let reg = RegExp(name + '=([^;]+)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
}

/**
 * 删除cookie
 **/
function delCookie(name) {
    setCookie(name, null, -1);
}


export {
    htmlDecode,
    completionWrap,
    setCookie,
    getCookie,
    delCookie
}
