import Vue from 'vue'
import axios from 'axios'
import CryptoJS from 'crypto-js'

// 请求接口的域名
const BASE_URL = config.host + '/wb-syg'

// 请求的超时
const DEFAULT_TIMEOUT = 100000

// 生成签名
function sign(url, method, data) {
  let appSecret = "0934ESXIRU913";  //加签秘钥
  if (url.search("oss") === -1) {
    let params = JSON.parse(JSON.stringify(data));
    if (method !== 'GET') {
      for (var key in params) {
        var typeString = Object.prototype.toString.call(params[key]);
        if (typeString === "[object Object]" || typeString === "[object Array]" || params[key] === null) {
          delete (params[key]); //过滤对象和数组
        }
      }
    }
    let requestBody = "";
    let sortedParams = Object.keys(params).sort();
    sortedParams.forEach((param) => {
      if (params[param]!==null) {
        requestBody += param + params[param];
      }
    });
    requestBody += appSecret;
    return CryptoJS.MD5(requestBody).toString().toUpperCase();
  } else {
    return null;
  }
}

/**
 * 数据请求
 * @param params 参数
 * @param method 请求类型 'GET' 'POST'
 * @return Promise
 */
export const request = async (params, method) => {
  let data = 'data'
  let url = params.url
  if (method.toLocaleLowerCase() === 'get') {
    data = 'params'
    // url = toggleUrlParams(url)
  }
  params.headers = params.headers || {};
  let loginToken = localStorage.getItem('loginToken');
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: BASE_URL + url,
      [data]: params.data,
      headers: {
        "X-Sign": sign(url, method, params.data),
        "X-Token": loginToken || '',
        ...params.headers
      },
      timeout: params.timeout || DEFAULT_TIMEOUT
    }).then((res) => {
      if (res.headers['x-token']) {
        localStorage.setItem('loginToken', res.headers['x-token']);
      }
      // 登陆已过期
      if (res.data.code === 403) {
        Vue.prototype.$message({
          showClose: true,
          message: '登录已过期！',
          type: 'error'
        });
        localStorage.removeItem('loginToken');
        localStorage.removeItem('userInfo');
        return location.reload();
      }
      if (res.data.code === 0) {
        resolve(res.data)
      } else {
        Vue.prototype.$message({
          showClose: true,
          message: res.data.message,
          type: 'error'
        });
      }
    }).catch((err) => {
      // router.replace('/404');
      reject(err)
    })
  })
}

/**
 * GET 请求
 * @param url 请求地址
 * @param data 请求参数
 * @param params 额外参数 例：hearders: {}
 */
export const GET = async (url, data, params) => {
  return request({
    url,
    data,
    ...(params || {})
  }, 'GET')
}

/**
 * POST 请求
 * @param url 请求地址
 * @param data 请求参数
 * @param params 额外参数 例：hearders: {}
 */
export const POST = async (url, data, params) => {
  return request({
    url,
    data,
    ...(params || {})
  }, 'POST')
}
