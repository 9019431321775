// 关于我们
export default [
    {
        path: '/about',
        name: '',
        component: () => import('@/views/About/About'),
        children: [
            {
                path: '',
                name: '走进博物院',
                component: () => import('@/views/About/Info'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'policy',
                name: '办院方针',
                component: () => import('@/views/About/Policy'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'history',
                name: '历史沿革',
                component: () => import('@/views/About/History'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'leader',
                name: '领导信息',
                component: () => import('@/views/About/Leader'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'duty',
                name: '主要职责',
                component: () => import('@/views/About/Duty'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'organ',
                name: '内设机构',
                component: () => import('@/views/About/Organ'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'director',
                name: '领导致辞',
                component: () => import('@/views/About/Director'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'event',
                name: '大事记',
                component: () => import('@/views/About/Event'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'message',
                name: '在线留言',
                component: () => import('@/views/About/Message'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    }
]
