// 导览
export default [
    {
        path: '/guide',
        name: '',
        component: () => import('@/views/Guide/Guide'),
        children: [
            {
                path: '',
                name: '开放时间',
                component: () => import('@/views/Guide/Info'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'notice',
                name: '公示公告',
                component: () => import('@/views/Guide/Notice'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'ticket',
                name: '票务政策',
                component: () => import('@/views/Guide/Ticket'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'facility',
                name: '服务设施',
                component: () => import('@/views/Guide/Facility'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'traffic',
                name: '交通指南',
                component: () => import('@/views/Guide/Traffic'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'route',
                name: '参观导览',
                component: () => import('@/views/Guide/Route'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'tourism',
                name: '文明旅游',
                component: () => import('@/views/Guide/Tourism'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    }
]
