// 展览
export default [
    {
        path: '/exhibition',
        name: '',
        component: () => import('@/views/Exhibition/Exhibition'),
        children: [
            {
                path: '',
                name: '临时展览',
                component: () => import('@/views/Exhibition/Lately'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'original',
                name: '原状陈列',
                component: () => import('@/views/Exhibition/Original'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'basic',
                name: '基本陈列',
                component: () => import('@/views/Exhibition/Basic'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'import',
                name: '引进展览',
                component: () => import('@/views/Exhibition/Import'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'export',
                name: '赴外展览',
                component: () => import('@/views/Exhibition/Export'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    },
    {
        path: '/exhibition/detail',
        name: '展览详情',
        component: () => import('@/views/Exhibition/ExhibitionDetail'),
        meta: {
            isStandard: true,
            isShowFooter: true
        }
    }
]
