<template>
  <div class="menu-box">
    <div v-if="title" class="menu-box-item head">{{ title }}</div>
    <template v-for="item in menuList">
      <div
          class="menu-box-item"
          :class="activeItem===item.key ? 'select' : ''"
          @click="onSelectItem(item)"
      >
        <span>{{ item.name }}</span>
        <div v-show="activeItem===item.key" class="menu-box-line"></div>
        <template v-if="item.submenu">
          <i :class="isShowSubmenu&&activeItem===item.key ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </template>
      </div>
      <div v-if="item.submenu&&isShowSubmenu&&activeItem===item.key" class="menu-box-submenu">
        <div
            class="menu-box-submenu-item"
            :class="activeItems===items.key ? 'select' : ''"
            v-for="items in item.submenu"
            @click="onSelectItems(item, items)"
        >
          <span>{{ items.name }}</span>
          <div v-show="activeItems===items.key" class="menu-box-point"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseMenuBox",
  props: {
    title: {
      type: String
    },
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    },
    query: {
      type: String
    },
    defaultQuery: {
      type: [String,Number]
    }
  },
  data() {
    return {
      activeItem: null,
      activeItems: null,
      isShowSubmenu: true
    }
  },
  watch: {
    $route: {
      immediate: true, // 监听到路由的变化立即执行
      handler(to) {
        if (this.query) {
          this.activeItem = to.query[this.query] || this.defaultQuery;
          this.activeItems = to.query[this.query] || this.defaultQuery;
        } else {
          let path = to.path;
          let index = path.lastIndexOf('/');
          this.activeItem = path.substring(index, path.length);
          this.activeItems = to.hash || '#';
        }
        this.isShowSubmenu = true;
      }
    }
  },
  methods: {
    onSelectItem(item) {
      if (item.submenu) {
        if (item.key === this.activeItem) {
          this.isShowSubmenu = !this.isShowSubmenu;
        } else {
          this.activeItems = item.submenu[0].key;
          this.isShowSubmenu = true;
          this.$emit('onSelect', item, item.submenu[0]);
        }
      } else {
        this.activeItems = null;
        this.isShowSubmenu = false;
        this.$emit('onSelect', item, null);
      }
      this.activeItem = item.key;
    },
    onSelectItems(item, items) {
      this.activeItems = items.key;
      this.$emit('onSelect', item, items);
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  width: 190px;

  &-item {
    width: 100%;
    height: 56px;
    //font-family: SourceHanSerifCN-Regular;
    font-size: 16px;
    color: #000000;
    //letter-spacing: 1px;
    //line-height: 56px;
    text-align: justify;
    font-weight: 400;
    background: rgba(255,255,255,0.5);
    cursor: pointer;
    border: 0.5px solid rgba(151,151,151,0.5);
    border-top: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 21px;
    padding-right: 16px;
    box-sizing: border-box;
    position: relative;

    &.head {
      //font-family: SourceHanSerifCN-Medium;
      font-size: 22px;
      color: #FFFFFF;
      letter-spacing: 1.38px;
      font-weight: 500;
      background: #A82813;
      border: none;
      cursor: auto;
      padding-left: 18px;

      &:hover {
        color: #FFFFFF;
      }
    }

    &.select {
      color: #A82813;
      font-weight: 500;
    }

    &:hover {
      color: #A82813;
    }
  }

  &-line {
    width: 2px;
    height: 24px;
    background: #A82813;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0,-50%);
  }

  &-submenu {
    width: 100%;
    border: 0.5px solid rgba(151,151,151,0.5);
    border-top: none;
    padding: 12px 12px 6px 32px;
    box-sizing: border-box;
    //overflow: hidden;
    //animation: showLine 0.5s;
    //animation-fill-mode: forwards;
    //
    //@keyframes showLine {
    //  0%{ transform: scale(1,0) }
    //  100%{ transform: scale(1) }
    //}


    &-item {
      //font-family: SourceHanSerifCN-Regular;
      font-size: 14px;
      color: #010101;
      letter-spacing: 1.56px;
      text-align: justify;
      line-height: 20px;
      font-weight: 400;
      cursor: pointer;
      margin-bottom: 8px;
      position: relative;

      &.select {
        color: #A82813;
        font-weight: 500;
      }

      &:hover {
        color: #A82813;
      }
    }
  }

  &-point {
    width: 4px;
    height: 4px;
    background: #A82813;
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translate(0,-50%);
  }
}
</style>
