<template>
  <div v-if="showMenu" class="menu-container" :class="fadeOut ? 'fadeOut' : 'fadeIn'">
    <img class="bg" :src="$imgHost+'/syg/static/img/bg_footer.png'" />
    <img class="close-icon" :src="require('@/assets/icon/icon_close.png')" @click="onClose"/>
    <div class="menu-list">
      <div
          v-for="item in menuList"
          class="menu-box"
          @click="onPage(item.url)"
      >{{ item[language] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseMenu",
  data() {
    return {
      lang: 'cn',
      menuList: menus,
      fadeOut: false,
      showMenu: false
    }
  },
  computed: {
    language() {
      switch (this.lang) {
        case "cn":
          return 'menuName';
        case "tw":
          return 'menuTWName';
        case "en":
          return 'menuENName';
        case "ja":
          return 'menuJAName';
        default:
          return 'menuName';
      }
    }
  },
  mounted() {
    this.lang = localStorage.getItem('lang') || 'cn';
  },
  methods: {
    onPage(url) {
      this.fadeOut = true;
      setTimeout(() => {
        this.showMenu = false;
        this.fadeOut = false;
        if (!url) return false;
        if (this.defaultPath === url) return false;
        this.$router.push(url);
      }, 300)
    },
    onClose() {
      this.fadeOut = true;
      setTimeout(() => {
        this.showMenu = false;
        this.fadeOut = false;
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  width: 100%;
  height: 101%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  &.fadeIn {
    animation: fadeIn 0.3s;
  }

  &.fadeOut {
    animation: fadeOut 0.3s;
  }

  @keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }

  .close-icon {
    width: 44px;
    height: 44px;
    transition: all 0.3s linear;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 50px;

    &:hover {
      transform: rotate(180deg);
      transition: all 0.3s linear;
    }
  }

  .menu-list {
    width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    .menu-box {
      opacity: 1;
      //font-family: SourceHanSerifCN-Regular;
      font-size: 22px;
      color: #D3AF68;
      letter-spacing: 1.38px;
      text-align: center;
      line-height: 30px;
      font-weight: 400;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        color: #FFFFFF;
      }
    }
  }
}
</style>
