// 藏品
export default [
    {
        path: '/collection',
        name: '',
        component: () => import('@/views/Collection/Collection'),
        children: [
            {
                path: '',
                name: '文物精粹',
                component: () => import('@/views/Collection/Essence'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'book',
                name: '古籍典藏',
                component: () => import('@/views/Collection/Book'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'donation',
                name: '藏品捐赠与征集',
                component: () => import('@/views/Collection/Donation'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'appreciate',
                name: '文物鉴赏常识',
                component: () => import('@/views/Collection/Appreciate'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'restore',
                name: '文物修复',
                component: () => import('@/views/Collection/Restore'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'preserve',
                name: '文物保护常识',
                component: () => import('@/views/Collection/Preserve'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    },
    {
        path: '/collection/detail',
        name: '藏品详情',
        component: () => import('@/views/Collection/CollectionDetail'),
        meta: {
            isStandard: true,
            isShowFooter: true
        }
    }
]
