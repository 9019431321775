// 文创
export default [
    {
        path: '/creation',
        name: '',
        component: () => import('@/views/Creation/Creation'),
        children: [
            {
                path: '',
                name: '文创产品',
                component: () => import('@/views/Creation/Product'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'design',
                name: '文创设计',
                component: () => import('@/views/Creation/Design'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'activity',
                name: '活动推送',
                component: () => import('@/views/Creation/Activity'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'authorization',
                name: 'IP授权',
                component: () => import('@/views/Creation/Authorization'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            },
            {
                path: 'service',
                name: '特色文化服务',
                component: () => import('@/views/Creation/Service'),
                meta: {
                    isStandard: true,
                    isShowFooter: true
                }
            }
        ]
    }
]
