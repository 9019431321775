import Vue from 'vue'
import VueRouter from 'vue-router'

import guide from "@/router/modules/guide";
import exhibition from "@/router/modules/exhibition";
import build from "@/router/modules/build";
import collection from "@/router/modules/collection";
import creation from "@/router/modules/creation";
import news from "@/router/modules/news";
import about from "@/router/modules/about";
import common from "@/router/modules/common";
import user from "@/router/modules/user";
import auth from "@/router/modules/auth";

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index/Index'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  // 3D展示
  {
    path: '/3d',
    name: 'Three',
    component: () => import('@/views/Three/Three'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  {
    path: '/3d/detail',
    name: 'ThreeDetail',
    component: () => import('@/views/Three/ThreeDetail'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  {
    path: '/3d/detail/full',
    name: 'ThreeFull',
    component: () => import('@/views/Three/ThreeFull'),
    meta: {
      isStandard: false,
      isShowFooter: false
    }
  },
  // 导览
  ...guide,
  // 展览
  ...exhibition,
  // 建筑
  ...build,
  // 藏品
  ...collection,
  // 教育
  {
    path: '/education',
    name: '教育',
    component: () => import('@/views/Education/Education'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  // 学术
  {
    path: '/academic',
    name: '学术',
    component: () => import('@/views/Academic/Academic'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  // 文创
  ...creation,
  // 虚拟博物馆
  {
    path: '/digital',
    name: '虚拟博物馆',
    component: () => import('@/views/Digital/Digital'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  // 资讯
  ...news,
  // 关于我们
  ...about,
  // 公共页面
  ...common,
  // 个人中心
  ...user,
  // 登录注册
  ...auth,
  // 下载中心
  {
    path: '/download',
    name: '下载中心',
    component: () => import('@/views/Download/Download'),
    meta: {
      isStandard: true,
      isShowFooter: true
    }
  },
  // 搜索
  {
    path: '/search',
    name: '搜索',
    component: () => import('@/views/Search/Search')
  },
  // 搜索列表
  {
    path: '/search/list',
    name: '搜索列表',
    component: () => import('@/views/Search/SearchList')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // 切换路由后滚动条置顶
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

// 解决 Vue 重复点击相同路由
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
