import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/global.scss'
import '@/assets/scss/font.scss'
import '@/assets/scss/element-variables.scss'
import moment from "moment"
import BaseMenuBox from "@/components/BaseMenuBox/BaseMenuBox";
import BasePagination from "@/components/BasePagination/BasePagination";

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$moment = moment;
Vue.prototype.$imgHost = config.imgHost;
Vue.component('BaseMenuBox', BaseMenuBox)
Vue.component('BasePagination', BasePagination)

// 路由拦截器
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) { // 没有匹配到当前路由
    next({ path: '/' })
  } else {
    let loginToken = localStorage.getItem('loginToken');
    if (!loginToken && to.meta.requireAuth) {
      Vue.prototype.$message({
        showClose: true,
        message: '请先登录！',
        type: 'error'
      });
      next({ path: '/auth/login' })
    }
    next()
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
