// 登录注册
export default [
    {
        path: '/auth',
        name: '',
        component: () => import('@/views/Auth/Auth'),
        children: [
            {
                path: 'login',
                name: '登录',
                component: () => import('@/views/Auth/Login'),
                meta: {
                    isFullScreen: true
                }
            },
            {
                path: 'register',
                name: '注册',
                component: () => import('@/views/Auth/Register'),
                meta: {
                    isFullScreen: true
                }
            }
        ]
    }
]
